import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import { difference } from 'lodash'
import PageHeader from '../../components/sections/PageHeader'
import { getImage } from "gatsby-plugin-image"
import CallToAction from '../../components/sections/CallToAction'
import PostCard from '../../components/PostCard'
export default function WorkPage({ data: { posts } }, ...props) {

    const postReduce = ['all', ...posts.categories]

    const [displayedCategory, setDisplayedCategory] = React.useState(['all'])


    return (
        <Layout >
            <Seo
                title="Resources | SEO, SEM, PPC Services & More | Majiska"
                description="Wether if you're seeking for the most recent digital marketing news or want to know what Team Majiska has been up to, our brilliant minds have you covered." />
            <PageHeader
                heading={`Resources`}
                content={`Wether if you're seeking for the most recent digital marketing news or want to know what Team Majiska has been up to, our brilliant minds have you covered.`}
            />
            <div className="container--padded  pb-resp-16-32">
                <div className="pb-16">
                    <div className="flex flex-wrap justify-center">

                        {postReduce.map(cat => {
                            return (
                                <div className={`mx-4 px-4 mb-2 cursor-pointer rounded-md capitalize ${displayedCategory == cat ? 'border border-accent' : null}`} onClick={() => {
                                    if (displayedCategory != cat) {
                                        setDisplayedCategory([cat])
                                    }
                                }} key={cat}>
                                    {cat}
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3">
                    {posts.edges.filter(({ node: post }) => {
                        if (displayedCategory == 'all') {
                            return true

                        } else {
                            return difference(displayedCategory, post.category).length === 0
                        }

                    })
                        .map(({ node: post }) => {

                            const image = getImage(post.banner)

                            return (
                                <PostCard
                                    key={post.id}
                                    timeToRead={post.content.childMdx.timeToRead}
                                    slug={post.slug}
                                    image={image}
                                    title={post.title}
                                    description={post.content.childMdx.excerpt}
                                    categories={post.category}
                                />
                            )
                        })}
                </div>
            </div>
            <CallToAction />
        </Layout>
    )
}

export const pageQuery = graphql`
  query {
    posts:allContentfulBlogPost(
        sort: {fields: createdAt, order: DESC}
      ) {
        categories: distinct(field: category)
        edges {
          node {
            title
            id
            slug
            category
            tag
            date:createdAt(formatString: "MMMM DD, YYYY")
            banner:featuredImage {
                gatsbyImageData(layout: FULL_WIDTH)
            }
            content:post {
              childMdx {
                timeToRead
                excerpt(pruneLength: 160)
              }
            }
          }
        }
      }
  }
`
