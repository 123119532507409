import * as React from 'react'
import { Link } from 'gatsby'
import truncate from 'lodash/truncate'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
interface PostCardProps {
    timeToRead: string;
    slug: string;
    image: IGatsbyImageData;
    title: string;
    description: string;
    categories: string[];
}

export default function PostCard({ slug, image, title, description, categories, timeToRead }: PostCardProps) {
    return (
        <div className="w-full sm:w-1/2 lg:w-1/3 px-3 mb-6"
        >
            <article className="resource h-full">
                <Link to={slug} className="resource__link relative flex flex-col h-full">
                    <div className="resource__fimage ">
                        <div className="relative overflow-hidden pt-66perc image image--center">
                            <GatsbyImage image={image} alt={`random`} />
                        </div>

                    </div>
                    <div className="resource__learnmore absolute bottom-0 left-0 w-full px-resp-5-8 bg-theme-dark h-16 flex items-center">
                        <div className="font-bold text-xs">Learn More</div>
                    </div>
                    <div className="resource__content p-resp-5-8 bg-theme-dark flex-1 relative flex flex-col">
                        <h4 className="resource__heading text-md leading-snug mb-2 lg:mb-3">
                            {title}
                        </h4>
                        <div className="resource__excerpt text-sm text-white leading-normal text-opacity-60 mb-5 lg:mb-7">
                            {truncate(description, { length: 120 })}
                        </div>
                        <div className="resource__footer flex justify-between items-center mt-auto">
                            <div className="resource__avatar flex items-center">
                                <div className=""></div>

                                <div className="text-white text-opacity-60 text-3xs font-bold capitalize">{parseInt(timeToRead) ? `${timeToRead} min read` : ''}</div>
                            </div>
                            <ul className="resource__categories flex items-center -mx-2">{(categories).map((cat, i) => {
                                return (
                                    <li key={i} className="px-2 text-white text-opacity-60 text-3xs font-bold capitalize">{cat}</li>
                                )
                            })}</ul>
                        </div>
                    </div>

                </Link>
            </article>
        </div>
    )
}